/**
 *	Styles
 */

@import 'settings/index';
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'vendors/index';
@import 'utilities/index';

// Utilities
@import 'node_modules/bootstrap/scss/utilities/api';

@import 'custom/index';

.login_logo img.logo_img {
    width: 200px;
}
.brand-title img.logo_img {
    width: 156px;
    margin: 15px auto 0;
}
.login_btn {
    background: rgb(213,120,29);
    background: linear-gradient(100deg, rgba(213,120,29,1) 0%, rgba(237,154,46,1) 100%) !important;
    border-color: #c18f5a !important;
}
.page-wrapper.login_wrapper {
    background-color: #1f2128 !important;
}
.qr_img {
    border: 1px solid #a4a4a4 !important;
}
// .form-control,
// .form-select,
// button,
// .card,
// .navigation .navigation-link-pill,
// ul.pagination {
//     border-radius: 0.25rem !important;
// }
// .table-modern > tbody > tr > :last-child, 
// .table-modern > thead > tr > :last-child {
//     border-top-right-radius: 0.25rem !important; 
//     border-bottom-right-radius: 0.25rem !important;
// }
// .table-modern > tbody > tr > :first-child, 
// .table-modern > thead > tr > :first-child {
//     border-top-left-radius: 0.25rem !important;
//     border-bottom-left-radius: 0.25rem !important;
// }
// .modern-design .footer,
// .modern-design .header,
// .modern-design .aside {
//     border-radius: 0.25rem !important;
// }
// .page-item:first-child .page-link {
//     border-top-left-radius: 0.25rem !important;
//     border-bottom-left-radius: 0.25rem !important;
// }
// .page-item:last-child .page-link {
//     border-top-right-radius: 0.25rem !important;
//     border-bottom-right-radius: 0.25rem !important;
// }
// .login_wrapper .rounded-3,
// .login_wrapper button.btn.rounded-1 {
//     border-radius: 0.25rem !important;
// }
button.btn.gold_btn {
    background-color: #d5781d !important;
    color: #fff;
    border-color: #d5781d !important;
    padding: 8px 30px;
}
button.btn.gold_btn a {
    color: #fff !important;
    text-decoration: none !important;
}
button.btn.gold_btn:hover {
    background-color: transparent !important;
    color: #d5781d;
    border-color: #d5781d !important;;
}
button.btn.gold_btn:hover a {
    color: #d5781d !important;
}
.first_row_dashcard {
    position: relative;
    z-index: 1;
}
.first_row_dashcard::before {    
    content: "";  
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    background-size: 30% !important;
    background-position: center right 20px !important;
    opacity: 0.3;    
    z-index: -1;
}
.btc_card::before {
    background: url("../assets/img/btc_icon.png") no-repeat;
}
.eth_card::before {
    background: url("../assets/img/eth_icon.png") no-repeat;
}
.usdt_card::before {
    background: url("../assets/img/usdt_icon.png") no-repeat;
}
.disatok_card::before {
    background: url("../assets/img/disa_token.png") no-repeat;
}
.qr_code img {
    border: 1px solid #787878;
    margin: 10px 0 20px;
}
.referral_info {
    color: #c08f5b;
    // background: #c08f5b;    
    // width: 100px;
    // text-align: center;
    // padding: 12px;
    // height: 100px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    font-size: 16px;
    border-radius: 50%;
}
.support_ticket_panel .accordion-item {
    margin: 15px 0;
    border: 1px solid #ed9a2e;
    border-radius: 15px !important;
}
.support_ticket_panel .accordion-button {
    background-color: transparent !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.support_ticket_panel .accordion-item:not(:last-of-type) .accordion-body::after {
    display: none;
}
.support_ticket_panel .stHeadText.subjectWidth {    
    text-align: left;
}
.stHeadText, .stHeadText small {
    font-size: 16px;
    text-transform: none;
    color: #d5781d;
    margin-right: 5px;
}
.stHeadText {
    color: #333;
}
.subjectWidth {
    width: 45%;
}
.ticketIdWidth {
    width: 25%;
}
.statusWidth {
    width: 15%;
}
.metaChatDetails {
    border-bottom: 1px solid #ed9a2e;
    padding-bottom: 12px;
    margin-bottom: 12px;
}
ul.ticketComments {
    margin: 0;
    padding: 0;
}
ul.ticketComments li:last-child {
    margin-bottom: 0;
}
ul.ticketComments li {
    list-style: none;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-around;
}
.ticketUserDetails {
    text-align: center;
    margin-right: 20px;
    width: 60px;
}
.userImg, .userImg img {
    border-radius: 50%;
}
.userImg {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: #d5781d;
}
.ticketDetails {
    width: calc(100% - 70px);
}
.messageTypeBox {
    margin-top: 20px;
}
.contact_form label {
    margin-bottom: 0;
    color: #555;
}
.footer_chat {
    width: 100%;
    padding: 10px 0 15px;
}
.footer_chat .form-group {
    margin: 0;
}
.input_position {
    position: relative;
}
.footer_chat .form-control {
    padding: 14px 11px;
    border-radius: 5px;
    height: 45px;
    text-transform: capitalize;
}
.flx_amount_just {
    position: absolute;
    top: 13px;
    right: 20px;
    color: #d5781d;
}
.form-group input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.button_section_chat input {
    z-index: 5!important;
}
.button_section_chat input {
    width: 20px;
    cursor: pointer;
}
.submitChat {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
ul.dash_overall_bal {
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}
ul.dash_overall_bal li {
    width: 50%;
}
.dash_overall_bal div {
    margin-bottom: 10px;
}
.dash_overall_bal div label {
    font-size: 16px;
    font-weight: 500;
    display: block;
}
.dash_overall_bal div label:nth-child(1) {
    color: #d5781d;
    margin-right: 5px;
    display: block;
}
select.buy_token_select {
    max-width: 190px !important;
    border-color: #f8f9fa !important;
    border-radius: 0 !important;
    padding-left: 50px;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
}
img.buytoken_select_img {
    width: 32px;
    position: absolute;
    top: 8px;
    right: 195px;
    z-index: 10;
}
.input-group button.btn-dark {
    background: linear-gradient(100deg, rgba(213,120,29,1) 0%, rgba(237,154,46,1) 100%) !important;
    border-color: #d5781d;
}
select.withdraw_token_select {
    max-width: 180px !important;        
    border-radius: 10px 0 0 10px !important;
    padding-left: 50px;    
    background-clip: padding-box !important;
    border: 1px solid #f8f9fa !important;    
    appearance: none;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%) !important;

}
span.withdraw_token_select_error {
    padding-left: 180px;
}
.withdraw_select_img {
    width: 32px;
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 999;
}
.buy_token_details {
    margin-bottom: 10px;
}
.buy_token_details div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}
.buy_token_details div label {
    font-size: 14px;
}
.member_card {
    background: #0a0705;
}
.member_card h2 {
    color: #fbf6f1;
    font-size: 20px;
}
.member_card h2 span {
    color: #d5781d;
}
.member_card img {
    width: 30px;
    margin-right: 12px;
}
.member_card .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
}
.member_card svg {
    margin-left: 10px;
    color: #eee;
    cursor: pointer;
}
.header-right button,
.header-right button:hover {
    background: #0a0705 !important;
    color: #fff !important;
    border-color: #0a0705;
}
.table-modern > tbody > tr > *, 
.table-modern > thead > tr > * {
    white-space: nowrap;
}
.notification_pre_table th button {
    background-color: transparent !important;
    padding: 0;
    border: 0;
    width: unset;
    pointer-events: none;
}
.notification_pre_table th button svg {
    width: 20px;
    height: 20px;
}
.notification_pre_table tbody td .form-check-input {
    width: 18px;
    height: 18px;
}
.faq_accord .accordion-item {
    margin-bottom: 20px;
    box-shadow: 0 0 3px 0px rgb(0 0 0 / 30%);
    border-radius: 10px;
}
.faq_accord .accordion-button.collapsed {
    border-radius: 10px;
}
.faq_accord button.accordion-button {    
    border-radius: 10px 10px 0 0;
}
.faq_accord .accordion-body::after {
    display: none !important;
}
ul.footer_links {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
ul.footer_links li {
    margin: 0 10px;
}
ul.footer_links li a {
    text-decoration: none;
}
ul#aside-demo-pages {
    display: none;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.notify_alert .alert-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.notify_alert .alert-text span:nth-child(1) {
    width: 70%;
}
.notify_alert .alert-text span:nth-child(2) {
    width: 30%;
    text-align: right;
}
.notification_btn_wrapper {
    position: relative;
}
span.notify_count {
    position: absolute;
    z-index: 10;
    background: #d5781d;
    color: #fff;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    right: -5px;
    top: -5px;
    font-size: 12px;
}
.btn-group button.btn.btn-primary {
    border-color: #ed9a2e;
    background: #ed9a2e;
}
.btn-group button.btn.btn-primary.active {
    box-shadow: none;
    border-color: #d5781d;
    background: #d5781d;
}
.referal_claim {
    display: flex;
    align-items: flex-start;
}
.referal_claim > div:first-child {
    width: 30%;
}
.balance_details_wrap {
    display: flex;
}
.balance_details_div {
    min-height: 220px;
}
// .balance_details_div {
//     width: 30%;
//     position: relative;
//     padding: 0 50px;
// }
// .balance_details_wrap .balance_details_div:nth-child(3) {
//     width: 40%;
// }
// .balance_details_div::after {
//     content: "";
//     background-color: #d5781d;
//     position: absolute;
//     width: 1px;
//     height: 100%;
//     top: 0;
//     right: 0;
// }
// .balance_details_wrap .balance_details_div:last-child:after {
//     display: none;
// }
input.form-control[type=file] {
    line-height: 32px;
}
.purchase_header img {
    border-radius: 15px;
}
.special-label {
    display: none;
}
.customSelect select{
    color: #323232 !important;
background-color: #f8f9fa !important;
background-clip: padding-box !important;
border: 1px solid #f8f9fa !important;
display: block !important;
width: 100% !important;
padding: 0.5rem 1rem !important;
font-size: 1rem !important;
font-weight: 600 !important;
line-height: 1.5 !important;
box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%) !important;
    border-radius: 1rem !important;
}
.react-tel-input .form-control {
    width: 100% !important;
    background-color: #f8f9fa !important; 
    background-clip: padding-box !important;
    border: 1px solid #f8f9fa !important;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%) !important;
    border-radius: 1rem !important;
}
.react-tel-input .selected-flag,
.react-tel-input .flag-dropdown {
    background-color: #f8f9fa !important; 
    background-clip: padding-box !important;
    border: none !important;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%) !important;
    border-radius: 1rem 0rem 0rem 1rem !important;
}
.referral_tier_table tbody tr:hover {
    background: rgb(11 6 1 / 10%);
    cursor: pointer;
}
.balance_details_div h3 {
    font-size: 18px;
    color: #0b0601;
    font-weight: 600;
}
.user_info > div {
    display: flex;
    align-items: center;
}
.user_info > div h4 {
    color: #d5781d;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}
.user_info > div h4 span {
    color: #212529;
    margin-left: 4px;
}
.svg-icon-2x {
    font-size: 20px;
    margin-left: 5px;
}
.user_info {
    margin-bottom: 8px;
}
.sec_note h5 {
    font-size: 18px;
    color: #0b0601;
    font-weight: 600;
}
.sec_note h6 {
    font-size: 14px;
}
.estimate_bal h4 {
    font-size: 16px;
}

// Dark Theme
[theme=dark] select.buy_token_select,
[theme=dark] select.withdraw_token_select {
    border-color: #343a40 !important; 
}
[theme=dark] .customSelect select{
    color: #ffffff !important;
display: block !important;
width: 100% !important;
padding: 0.5rem 1rem !important;
font-size: 1rem !important;
font-weight: 600 !important;
line-height: 1.5 !important;
background-color: #212529 !important; 
    background-clip: padding-box !important;
    border: 1px solid #343a40 !important;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%) !important;
    border-radius: 1rem !important;
}
[theme=dark] .react-tel-input .form-control {
    width: 100% !important;
    background-color: #212529 !important; 
    background-clip: padding-box !important;
    border: 1px solid #343a40 !important;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%) !important;
    border-radius: 1rem !important;
}
[theme=dark] .react-tel-input .selected-flag,
[theme=dark] .react-tel-input .flag-dropdown {
    border: 1px solid #343a40 !important;
    background-color: #212529 !important; 
    background-clip: padding-box !important;    
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%) !important;
    border-radius: 1rem 0rem 0rem 1rem !important;
}
[theme=dark] .react-tel-input .country-list {
    background-color: #242731;
}
[theme=dark] .react-tel-input .country-list .country.highlight,
[theme=dark] .react-tel-input .country-list .country:hover {
    background-color: #343a40;
}
[theme=dark] .link-dark,
[theme=dark] .balance_details_div h3,
[theme=dark] .sec_note h5,
[theme=dark] .user_info > div h4 span {
    color: #fff !important;
}
[theme=dark] .stHeadText {
    color: #cfcfcf;
}
[theme=dark] .contact_form {
    margin-bottom: 0;
    color: #a4a4a4;
}

@media screen and (max-width:1366px) {
    // .balance_details_div::after {
    //     right: 0;
    // }
    .estimate_bal button.btn.gold_btn {        
        padding: 8px 15px;
    }
}
@media screen and (max-width:1300px) {    
    // .balance_details_wrap {
    //     flex-wrap: wrap;
    // }
    // .balance_details_wrap .balance_details_div:nth-child(3) {
    //     width: 100%;
    //     margin-top: 30px;
    // }
    // .balance_details_wrap .balance_details_div {
    //     width: 50%;
    // }
    // .balance_details_wrap .balance_details_div:nth-child(2):after {
    //     display: none;
    // }
    button.btn.gold_btn {       
        padding: 8px 20px;
    }
}
@media screen and (max-width:991px) {
    .balance_details_div {
        margin-bottom: 15px;
    }
    // .balance_details_wrap .balance_details_div {
    //     width: 100%;
    //     margin-top: 30px;
    // }
    // .balance_details_wrap .balance_details_div::after {
    //     width: 90%;
    //     height: 1px;
    //     top: unset;
    //     right: 0;
    //     left: 0;
    //     bottom: -22px;
    //     margin: 0 auto;
    // }
    // .balance_details_wrap .balance_details_div:nth-child(2):after {
    //     display: block;
    // }
    .balance_details_div {       
        padding: 0 20px;
    }
    .balance_details_div {
        min-height: auto;
    }
}
@media screen and (max-width:767px) {
    ul.dash_overall_bal li {
        width: 100%;
    }
    button.btn.gold_btn {       
        padding: 8px 15px;
    }
}